
import Configuration from './configuration';
import Resources from './resources';
import cookie from 'react-cookies';

class DataServices {
    
    constructor() {
        this.config = new Configuration();
        this.resources = new Resources();
    }
    
    getTokenFromCookie( ){
        var token = cookie.load( this.config.COOKIE_NAME_TOKEN );
        var sessionToken = window.sessionStorage.getItem(this.config.COOKIE_NAME_TOKEN);
        if( !token || !sessionToken ){
            window.location.href='/login';
        }
        return token;
    }
    
    checkTokenFromCookie(){
        return cookie.load( this.config.COOKIE_NAME_TOKEN );
    }
    
    removeTokenCookie( ){
        cookie.remove( this.config.COOKIE_NAME_TOKEN, { path: '/' });
        window.sessionStorage.removeItem(this.config.COOKIE_NAME_TOKEN);
        window.sessionStorage.removeItem(this.config.COOKIE_NAME_USER_DATA);
        window.sessionStorage.removeItem(this.config.COOKIE_NAME_AUTH);
        window.location.href='/login';
    }
    
    async authorize( data , serviceName) {
    console.log(this.resources.BACKEND_SIDE_BASE_URL + serviceName);

         return fetch( this.resources.BACKEND_SIDE_BASE_URL + serviceName, {
                  method: "POST",
                  mode: "cors",
                  headers: {
                             "Content-Type": "application/json",
                              },
                  body: JSON.stringify( data )
                })
                  .then(response => {
                    if (!response.ok) {
                        this.handleResponseError(response);
                    }

                    return response.json();
                  })
                  .catch(error => {
                    this.handleError(error);
                  });
    }
    
    async retrieve(serviceName,serviceAction) {
        return fetch( this.resources.BACKEND_SIDE_BASE_URL +serviceName+ serviceAction , {

          method: "GET",
          mode: "cors",
          headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer " + this.getTokenFromCookie()
            }
        })
          .then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            }
            return response.json();
          })
          .then(json => {
            //console.log("Retrieved items:");
            //console.log(json);
            const items = json;
            return items;
          })
          .catch(error => {
            this.handleError(error);
        });
    }
        
    async retrievePOST( data, serviceName ) {
        return fetch( this.resources.BACKEND_SIDE_BASE_URL + serviceName, {
          method: "POST",
          mode: "cors",
          headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer " + this.getTokenFromCookie()
            },
          body: JSON.stringify( data )
        })
          .then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            }
           
            return response.json();
          })
          .catch(error => {
            this.handleError(error);
          });
    }
    
    async createItem( newItem, serviceName ) {
        return fetch( this.resources.BACKEND_SIDE_BASE_URL + serviceName, {
          method: "POST",
          mode: "cors",
          headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer " + this.getTokenFromCookie()
            },
          body: JSON.stringify(newItem)
        })
          .then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            }
           
            return response.json();
          })
          .catch(error => {
            this.handleError(error);
          });
    }
    
    async getItem( serviceName ) {
        return fetch( this.resources.BACKEND_SIDE_BASE_URL + serviceName, {
          method: "GET",
          mode: "cors",
          headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer " + this.getTokenFromCookie()
            }
        })
          .then(response => {
            if (!response.ok) {
                this.handleResponseError(response)
            }
            return response.json();
          })
          .then(json => {
//            console.log("Retrieved items:");
//            console.log(json);
            const items = json;
            return items;
          })
          .catch(error => {
            this.handleError(error);
        });
    }
    
    async updateItem( updatedItem, serviceName ) {
        return fetch( this.resources.BACKEND_SIDE_BASE_URL + serviceName, {
          method: "PUT",
          mode: "cors",
          headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer " + this.getTokenFromCookie()
            },
          body: JSON.stringify(updatedItem)
        })
          .then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            }
            return response.json();
          })
          .catch(error => {
            this.handleError(error);
          });
    }
    
    async cancelItem( serviceName, serviceAction ) {
        return fetch( this.resources.BACKEND_SIDE_BASE_URL + serviceName + serviceAction, {
            method: "DELETE",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer " + this.getTokenFromCookie()
            },
        })
          .then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            }
            return response.json();
          })
          .catch(error => {
            this.handleError(error);
          });
    }
    
    handleResponseError(response) {
        if( response.status === 401){
            this.removeTokenCookie( );
        }
        throw new Error("HTTP error, status = " + response.status);
    }
    handleError(error) {
        console.log(error.message);
    }

    getRowPerPage( ){
        var rowPerPage = cookie.load( this.config.COOKIE_NAME_ROW_PER_PAGE );
        if( !rowPerPage ){
            rowPerPage = this.config.DEFAULT_ROW_PER_PAGE;
        }
        return rowPerPage;
    }

    setRowPerPage( rowPerPage ){
        let d = new Date();
        d.setTime( d.getTime() + (1000 * 60 * 60 * 24 * 14) );
        cookie.save(this.config.COOKIE_NAME_ROW_PER_PAGE, rowPerPage, { path: '/', d, maxAge:(1000 * 60 * 60 * 24 * 14) });
    }
    getTokenforDownload(){
     var token = cookie.load( this.config.COOKIE_NAME_TOKEN );
     return token;
    }

    download( newItem, serviceAction , name) {
        let anchor = document.createElement("a");
        anchor.setAttribute('id', 'tmpLink');
        document.body.appendChild(anchor);
        return fetch( this.resources.BACKEND_SIDE_BASE_URL + serviceAction, {
           method: "POST",
           mode: "cors",
          headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer " + this.getTokenFromCookie()
            },
          body: JSON.stringify(newItem)
        })
        .then(response => response.blob())
        .then(blobby => {
            let objectUrl = window.URL.createObjectURL(blobby);
            anchor.href = objectUrl;
            anchor.download = name;
            anchor.click();
            window.URL.revokeObjectURL(objectUrl);
        })
        .catch(error => {
            this.handleError(error);
        });
    }

    storeCurrentUserData( userInfo ){
        window.sessionStorage.setItem(this.config.COOKIE_NAME_USER_DATA, JSON.stringify(userInfo));
    }

    /**
    Sample usage
    var userData = this.dataService.getCurrentUserData();
    var name = userData['name']
    var guid = userData['guid']
    var avatar = userData['avatar']
    var roleName = userData['roleName']
    var branchGuid = userData['branchGuid']
    var branchType = userData['branchType']
    var supervisorGuid = userData['supervisorGuid']
    */
    getCurrentUserData( ){
        var sessionData = window.sessionStorage.getItem(this.config.COOKIE_NAME_USER_DATA);
        if( !sessionData ){window.location.href='/login';}
        return JSON.parse(sessionData);
    }


    /**
    Sample usage
    var auth = this.dataService.getAuthoritiesData( );
    if( auth.includes('ORGANIZATION_READ') ){
        console.log('Hello, I have this privilege');
    }
    */
    getAuthoritiesData( ){
        var sessionData = window.sessionStorage.getItem(this.config.COOKIE_NAME_AUTH);
        if( !sessionData ) return null; //{window.location.href='/login';}
        return sessionData;
    }

    async retrievePUT( data, serviceName ) {
        return fetch( this.resources.BACKEND_SIDE_BASE_URL + serviceName, {
          method: "PUT",
          mode: "cors",
          headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer " + this.getTokenFromCookie()
            },
          body: JSON.stringify( data )
        })
          .then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            }

            return response.json();
          })
          .catch(error => {
            this.handleError(error);
          });
    }

    async retrieveDELETE( serviceName, serviceAction) {
        return fetch( this.resources.BACKEND_SIDE_BASE_URL + serviceName + serviceAction , {
          method: "DELETE",
          mode: "cors",
          headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer " + this.getTokenFromCookie()
            }
        })
          .then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            }
            return response.json();
          })
          .then(json => {
            const items = json;
            return items;
          })
          .catch(error => {
            this.handleError(error);
        });
    }

    getAdminDashboardHeadCountOption( ){
        var optionName = cookie.load( this.config.COOKIE_NAME_ADMIN_DASHBOARD_HEADCOUNT_OPTION );
        if( !optionName ){
            optionName = 'week';
        }
        return optionName;
    }

    setAdminDashboardHeadCountOption( optionName ){
        let d = new Date();
        d.setTime( d.getTime() + (1000 * 60 * 60 * 24 * 14) );
        cookie.save(this.config.COOKIE_NAME_ADMIN_DASHBOARD_HEADCOUNT_OPTION, optionName, { path: '/', d, maxAge:(1000 * 60 * 60 * 24 * 14) });
    }

    downloadByGet( serviceAction , name) {
        let anchor = document.createElement("a");
        anchor.setAttribute('id', 'tmpLink');
        document.body.appendChild(anchor);
        return fetch( this.resources.BACKEND_SIDE_BASE_URL + serviceAction, {
           method: "GET",
           mode: "cors",
          headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer " + this.getTokenFromCookie()
            }
        })
        .then(response => response.blob())
        .then(blobby => {
            let objectUrl = window.URL.createObjectURL(blobby);
            anchor.href = objectUrl;
            anchor.download = name;
            anchor.click();
            window.URL.revokeObjectURL(objectUrl);
        })
        .catch(error => {
            this.handleError(error);
        });
    }

    async retrieveWithoutToken(serviceName,serviceAction) {
        return fetch( this.resources.BACKEND_SIDE_BASE_URL +serviceName+ serviceAction , {

          method: "GET",
          mode: "cors"
        })
          .then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            }
            return response.json();
          })
          .then(json => {
            //console.log("Retrieved items:");
            //console.log(json);
            const items = json;
            return items;
          })
          .catch(error => {
            this.handleError(error);
        });
    }

    getDeviceTokenFromCookie( ){
        var token = cookie.load( this.config.COOKIE_NAME_DEVICE_TOKEN );
        var sessionToken = window.sessionStorage.getItem(this.config.COOKIE_NAME_DEVICE_TOKEN);
        if( !token || !sessionToken ){
            window.location.href='/sign-up';
        }
        return token;
    }

    async retrievePOSTForRegister( data, serviceName ) {
        return fetch( this.resources.BACKEND_SIDE_BASE_URL + serviceName, {
          method: "POST",
          mode: "cors",
          headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer " + this.getDeviceTokenFromCookie()
            },
          body: JSON.stringify( data )
        })
          .then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            }

            return response.json();
          })
          .catch(error => {
            this.handleError(error);
          });
    }

    async retrieveGetForRegister(serviceName,serviceAction) {
        return fetch( this.resources.BACKEND_SIDE_BASE_URL +serviceName+ serviceAction , {
          method: "GET",
          mode: "cors",
          headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer " + this.getDeviceTokenFromCookie()
            }
        })
          .then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            }
            return response.json();
          })
          .then(json => {
            const items = json;
            return items;
          })
          .catch(error => {
            this.handleError(error);
        });
    }

    async retrievePOSTWithoutToken( data, serviceName ) {
        return fetch( this.resources.BACKEND_SIDE_BASE_URL + serviceName, {
          method: "POST",
          mode: "cors",
          headers: {
                "Content-Type": "application/json"
            },
          body: JSON.stringify( data )
        })
          .then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            }

            return response.json();
          })
          .catch(error => {
            this.handleError(error);
          });
    }

    downloadPOSTWithoutToken( newItem, serviceAction , name) {
        let anchor = document.createElement("a");
        anchor.setAttribute('id', 'tmpLink');
        document.body.appendChild(anchor);
        return fetch( this.resources.BACKEND_SIDE_BASE_URL + serviceAction, {
           method: "POST",
           mode: "cors",
          headers: {
                "Content-Type": "application/json"
            },
          body: JSON.stringify(newItem)
        })
        .then(response => response.blob())
        .then(blobby => {
            let objectUrl = window.URL.createObjectURL(blobby);
            anchor.href = objectUrl;
            anchor.download = name;
            anchor.click();
            window.URL.revokeObjectURL(objectUrl);
        })
        .catch(error => {
            this.handleError(error);
        });
    }

    removeUserData( ){
        cookie.remove( this.config.COOKIE_NAME_TOKEN, { path: '/' });
        window.sessionStorage.removeItem(this.config.COOKIE_NAME_TOKEN);
        window.sessionStorage.removeItem(this.config.COOKIE_NAME_USER_DATA);
        window.sessionStorage.removeItem(this.config.COOKIE_NAME_AUTH);
    }

    getLanguage( ){
        var rowPerPage = cookie.load( this.config.COOKIE_NAME_LANGUAGE );
        if( !rowPerPage ){
            rowPerPage = 'en';
        }
        return rowPerPage;
    }

    setLanguage( rowPerPage ){
        let d = new Date();
        d.setTime( d.getTime() + (1000 * 60 * 60 * 24 * 14) );
        cookie.save(this.config.COOKIE_NAME_LANGUAGE, rowPerPage, { path: '/', d, maxAge:(1000 * 60 * 60 * 24 * 14) });
    }

    setCurrentUserDashboardDateRange( statusRequest ){
        window.sessionStorage.setItem(this.config.COOKIE_NAME_DASHBOARD_DATE_RANGE, JSON.stringify(statusRequest));
    }

    getCurrentUserDashboardDateRange( ){
        var statusRequest = window.sessionStorage.getItem(this.config.COOKIE_NAME_DASHBOARD_DATE_RANGE);
        if( statusRequest )
            return JSON.parse(statusRequest);
        else
            return { startDate:null, endDate: null, branchGuid: null, dateParam:'month' };

    }

    storeJoinedBranches( branchesInfo ){
        window.sessionStorage.setItem(this.config.COOKIE_NAME_JOINED_BRANCHES, JSON.stringify(branchesInfo));
    }
    getJoinedBranches( ){
        var sessionData = window.sessionStorage.getItem(this.config.COOKIE_NAME_JOINED_BRANCHES);
        if( !sessionData ){window.location.href='/login';}
        return JSON.parse(sessionData);
    }

    getLastOrderIndex( ){
        var index = cookie.load( this.config.COOKIE_NAME_LAST_ORDER_INDEX );
        if( !index ){
            index = 0;
        }
        return index;
    }

    setLastOrderIndex( index ){
        let d = new Date();
        d.setTime( d.getTime() + (1000 * 60 * 60 * 24 * 14) );
        cookie.save(this.config.COOKIE_NAME_LAST_ORDER_INDEX, index, { path: '/', d, maxAge:(1000 * 60 * 60 * 24 * 14) });
    }
    setRowPerPageGrid( rowPerPage ){
        let d = new Date();
        d.setTime( d.getTime() + (1000 * 60 * 60 * 24 * 14) );
        cookie.save(this.config.COOKIE_NAME_ROW_PER_PAGE_GRID, rowPerPage, { path: '/', d, maxAge:(1000 * 60 * 60 * 24 * 14) });
    }

    getRowPerPageGrid( ){
        var rowPerPage = cookie.load( this.config.COOKIE_NAME_ROW_PER_PAGE_GRID );
        if( !rowPerPage ){
            rowPerPage = this.config.DEFAULT_ROW_PER_PAGE_GRID;
        }else
            rowPerPage = 9;
        return rowPerPage;
    }


    setSwitchBranchGuid( branchGuid ){
        if( branchGuid && branchGuid.length>0 )
            window.sessionStorage.setItem( this.config.COOKIE_NAME_SWITCH_BRANCH_GUID, branchGuid );
        else
            window.sessionStorage.removeItem(this.config.COOKIE_NAME_SWITCH_BRANCH_GUID);
    }

    getSwitchBranchGuid( ){
        var sessionData = window.sessionStorage.getItem(this.config.COOKIE_NAME_SWITCH_BRANCH_GUID);
        if( !sessionData )
            return '';
        else
            return sessionData;
    }
    setSwitchBranchName( branchName ){
        if( branchName && branchName.length>0 )
            window.sessionStorage.setItem( this.config.COOKIE_NAME_SWITCH_BRANCH_NAME, branchName );
        else
            window.sessionStorage.removeItem(this.config.COOKIE_NAME_SWITCH_BRANCH_NAME);
    }

    getSwitchBranchName( ){
        var sessionData = window.sessionStorage.getItem(this.config.COOKIE_NAME_SWITCH_BRANCH_NAME);
        return sessionData;
    }

    setCurrentUserPOSPrivilege( statusRequest ){
        window.sessionStorage.setItem(this.config.COOKIE_NAME_POS_PRIVILEGE, JSON.stringify(statusRequest));
    }

    getCurrentUserPOSPrivilege( ){
        var statusRequest = window.sessionStorage.getItem(this.config.COOKIE_NAME_POS_PRIVILEGE);
        if( statusRequest )
            return JSON.parse(statusRequest);
        else
            return { name:'allow_pos', value: 'false', guid: null };

    }

    setShipperLink( linkId ){
        if( linkId && linkId.length>0 )
            window.sessionStorage.setItem( this.config.COOKIE_NAME_SHIPPER_ID, linkId );
        else
            window.sessionStorage.removeItem(this.config.COOKIE_NAME_SHIPPER_ID);
    }

    getShipperLink( ){
        var sessionData = window.sessionStorage.getItem(this.config.COOKIE_NAME_SHIPPER_ID);
        return sessionData;
    }

}
export default DataServices;